import React, { useRef, useEffect } from 'react';
import { Box, List, ListItem,  Typography,  Avatar } from '@mui/material';
import Icon from '@mdi/react';
import { mdiMedicationOutline} from '@mdi/js';
import Typewriter from 'components/Typewrite/typewrite';


const MessageList = ({ messages, color }) => {
    const messagesEndRef = useRef(null);
  
    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);
  
    return (
      <Box sx={{ flexGrow: 1, overflowY: 'auto', paddingLeft: 15, overflowX: "auto", paddingRight:20,paddingBottom:10, maxWidth:'100%',backgroundColor:{color} }}>
        <List>
          {messages.map((message, index) => (
            <ListItem key={index} sx={{ display: 'flex', alignItems: 'flex-start'}}>
              <Avatar sx={{ marginRight: 2, backgroundColor: message.user === 'You' ? '#003366' : '#0C80A4' }}>
                {message.user === "You" ? message.user.charAt(0) : <Icon path={mdiMedicationOutline} size={1} />}
              </Avatar>
              <Box
                sx={{
                  color: '#fff',
                  borderRadius: 2,
                  padding: .5,
                  margin: 1,
                  maxWidth: '100%',
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                  backgroundColor: "0C80A4"
                }}
              >
                <Typography variant="subtitle2" sx={{fontWeight:700, color:"#fff"}}>{message.user}</Typography>
                <Typography variant="body1" sx={{color:"#fff"}}>{message.user === "You" ? message.text :<Typewriter text={message.text} delay={50}/>}</Typography>
                <Typography variant="caption" display="block" sx={{ color: '#fff' }}>
                  {message.time}
                </Typography>
              </Box>
            </ListItem>
          ))}
          <div ref={messagesEndRef} />
        </List>
      </Box>
    );
  };

  export default MessageList;