import React, { useRef, useEffect, useState } from 'react';
import { Box, List, ListItem,  Typography,  Avatar } from '@mui/material';
import Icon from '@mdi/react';
import { mdiMedicationOutline} from '@mdi/js';
import PulseLoader from "react-spinners/PulseLoader";
import Typewriter from 'components/Typewrite/typewrite';



const MessageCitationList = ({ messages, handleOpen, color, loading }) => {
    const messagesEndRef = useRef(null);
    const delay = 60;
    
  
    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);


  const [displayedCitations, setDisplayedCitations] = useState(Array(messages.length).fill(false));

  useEffect(() => {
    messages.forEach((message, index) => {
      if (message.user !== "You") {
        let duration;
        if (message.text){
        duration = message.text.length * delay;
        }
        else {
          duration = 0
        }
        console.log("In Message CitationLists")
        setTimeout(() => {
          setDisplayedCitations(prev => {
            const newDisplay = [...prev];
            newDisplay[index] = true;
            return newDisplay;
          });
        }, duration);
      }
    });
  }, [messages]);
  
    return (
   
    <Box sx={{ color:"#fff", flexGrow: 1, overflowY: 'auto', paddingLeft: 15, overflowX: "auto", paddingRight: 20, paddingBottom: 10, maxWidth: '100%', backgroundColor:{color} }}>
        <List>
            {messages.map((message, index) => (
            <ListItem key={index} sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Avatar sx={{ marginRight: 2, backgroundColor: message.user === 'You' ? '#003366' : '#0C80A4' }}>
                {message.user === "You" ? message.user.charAt(0) : <Icon path={mdiMedicationOutline} size={1} />}
                </Avatar>
                <Box
                sx={{
                    color: '#fff',
                    borderRadius: 2,
                    padding: .5,
                    margin: 1,
                    maxWidth: '100%',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    backgroundColor: "0C80A4"
                }}
                >
                <Typography variant="subtitle2" sx={{ fontWeight: 700, color: "#fff" }}>{message.user}</Typography>
                <Typography variant="body1" sx={{ color: "#fff" }}>{message.user === "You" ? message.text : <Typewriter text={message.text} delay={50} />}</Typography>
                {displayedCitations[index] && message.citations && message.citations.length > 0 && (
                message.citations.map((citation, citationIndex) => (
                  <Typography
                    variant="body2"
                    component="a"
                    key={citationIndex}
                    onClick={() => handleOpen(citation)}
                    sx={{ cursor: 'pointer', textDecoration: 'underline', color: '#fff' }}
                  >
                    <Typewriter text={citation.printed_file_name +"\n"} delay={70} sx={{"display":'block'}}/>
                  </Typography>
                ))
              )}
                <Typography variant="caption" display="block" sx={{ color: '#fff' }}>
                    {message.time}
                </Typography>
                </Box>
            </ListItem>
            ))}
      {loading && (
          <ListItem key="loading" sx={{ display: 'flex', alignItems: 'flex-start' }}>
            <Avatar sx={{ marginRight: 2, backgroundColor: '#0C80A4' }}>
              <Icon path={mdiMedicationOutline} size={1} />
            </Avatar>
            <Box
              sx={{
                color: '#fff',
                borderRadius: 2,
                padding: 0.5,
                margin: 1,
                maxWidth: '100%',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                backgroundColor: "0C80A4"
              }}
            >
              <PulseLoader color="#ffffff" loading={loading} size={15} />
            </Box>
          </ListItem>
        )}
            <div ref={messagesEndRef} />
        </List>
        </Box>
    );
  };

  export default MessageCitationList;