import React, { useState, useEffect, useRef } from 'react';
import { Box,TextField, Button,  Typography} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MKBox from "components/MKBox"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PatientCheckInForm from './PatientCheckinForm';
import MessageList from 'components/MessageList/messagelist'
// import { useNavigate } from 'react-router-dom';


const theme = createTheme({
  palette: {
    primary: {
      main: '#0C80A4',
    },
    secondary: {
      main: '#C45911',
    },
    background: {
      default: '#eeeeee',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
  multilineColor:{
    color:'red'
},
  typography: {
    // fontFamily: 'Berlin Sans FB',
    h3: {
      color: '#FFFFFF',
    },
    h5: {
      color: '#FFFFFF',
    },
    body1: {
      color: '#FFFFFF',
    },
    h6: {
      color: '#FFFFFF',
    },
    body2: {
      color: '#FFFFFF',
    },
  },
});


const serverBaseURL = process.env.REACT_APP_BACKEND_URL;
//const serverBaseURL = "https://www.projectdelphi.ai"
//const serverBaseURL = "http://localhost:8000"

function NursePage() {

  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);

  const [getPatientInfo,setGetPatientInfo] = useState(true);

  const [thread_id, setThread_id] = useState('');
  const [assistant_id, setAssistantId] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLasttName] = useState('');
  // const [birthdate, setBirthdate] = useState('');
  // const [socialSecurity, setSocialSecurity] = useState('');
  // const navigate = useNavigate();
  


  const handleFormSubmit = (formData) => {
    setGetPatientInfo(false)
   
    handleInitialize(formData.firstName)
    // You can now use formData as needed, e.g., send it to an API, store in state, etc.
  };

  const handleInitialize = async (firstName)=>{
    try {
  
      const response = await fetch(`${serverBaseURL}/api/get-initial-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName:`${firstName}` }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      setThread_id(data.thread_id)
      setAssistantId(data.assistant_id)
      typeOut(data.message); // or whatever structure your data has
      // Optionally, you can use the commented code if you want to update some state
      // setResponse((prev) => prev + data.message);

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  
  // const handleInputChange = (event) => {
  //   setInput(event.target.value);
  // };

  const handleSend = () => {
    if (input.trim() !== '') {
      const newMessage = { user: 'You', text: input, time: new Date().toLocaleTimeString() };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const query = input
      setInput('');
      handleResponse(query)
    }
  };

  const handleResponse = async (query) => {
    if (query ===""){
      console.log("No input");
      return
    }
    try {
      
      const response = await fetch(`${serverBaseURL}/api/continue-patient-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id : thread_id, new_message: query, assistant_id: assistant_id }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok now');
      }
     
      const data = await response.json();
   

      // Assuming data contains the information you want to process
      typeOut(data.message); // or whatever structure your data has
      // Optionally, you can use the commented code if you want to update some state
      // setResponse((prev) => prev + data.message);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  const typeOut = (text) => {
    // const keyword = "DOCTOR:";
    // const index = text.indexOf(keyword);
    // const beforeDoctor = index !== -1 ? text.substring(0, index) : text;

    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];

      if (lastMessage && lastMessage.user === 'AI Patient CheckIn Bot') {
        const updatedMessage = {
          ...lastMessage,
          text: lastMessage.text + text + (/[.!?]$/.test(text) ? ' ' : '')  // Add a space only if the text ends with a punctuation mark
        };
        return [...prevMessages.slice(0, -1), updatedMessage];
      } else {
        const newMessage = {
          user: 'AI Patient CheckIn Bot',
          text: text + (/[.!?]$/.test(text) ? ' ' : ''),  // Add a space only if the text ends with a punctuation mark
          time: new Date().toLocaleTimeString()
        };
        return [...prevMessages, newMessage];
      }
    });

    // Navigate to "patient-dashboard" after 20 seconds only if "DOCTOR:" is found in the message
    // if (index !== -1) {
    //   setTimeout(() => {
    //     navigate('/patient-dashboard');
    //   }, 20000);
    // }
  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Cleanup on component unmount
  useEffect(() => { 
    scrollToBottom();
  }, [messages]);


  return (
    
    <ThemeProvider theme={theme}>
  {getPatientInfo ? (
    <>
    <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh', background: "linear-gradient(to right,#003366, #00AACC, #003366)", color: '#fff'}}>
    <MKBox>
        <Typography variant="h4" gutterBottom sx={{ textShadow: "2px 2px 4px #000000" , flexGrow: 1, marginTop:"50px" , color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}}textAlign={"center"}>
        Welcome New Patient!
        </Typography>
        </MKBox>
        <MKBox>

        <Typography variant="h6"  gutterBottom  sx={{ textShadow: "2px 2px 4px #000000" ,marginRight: "100px",marginLeft:"100px", flexGrow: 1,color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}} textAlign={"center"}>
        Thank you for choosing us as your medical provider. We are dedicated to assisting you in the best way 
        possible. Please fill out our form and speak with our Patient AI Bot. The bot is designed to help 
        articulate your concerns and ensure all your symptoms are properly documented. Feel free to discuss 
        all your issues, and the Patient AI Bot will provide a summary to your doctor to better prepare for your 
        visit. 
        </Typography>
        </MKBox>
   
   <div>
      <PatientCheckInForm onFormSubmit={handleFormSubmit} />
    </div>
    </Box>
    </>):(
      <>
      <Box sx={{display: 'flex', flexDirection: 'column', height: '90vh', background: "linear-gradient(to right,#003366, #00AACC, #003366)", color: '#fff'}}>
    <MessageList messages={messages} color="#004778"/>
    <Box sx={{ display: 'flex', padding: 1,paddingBottom:3,backgroundColor:"#004778"}}>
      <TextField
        variant="outlined"
        placeholder="Type a message"
        fullWidth
        multiline
        inputProps={{ style: { color: "#fff" } }}
        minRows={2}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => { if (e.key === 'Enter' && !e.shiftKey) { handleSend(); e.preventDefault(); } }}
        sx={{
          
          '& .MuiInputBase-root': {
            color: '#555',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#555',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#555',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#555',
            },
          },
          
          paddingLeft:20,
          paddingRight:10,
        }}
      />
      <Button variant="contained" color="primary" onClick={handleSend}   sx={{
    marginLeft: 1,
    backgroundColor: '#0C80A4',
    '&:hover': {
      backgroundColor: '#065a7c',
    },
    // padding: '10px 20px',
    // fontSize: '16px',
    // borderRadius: '8px',
  }}>
        <SendIcon />
      </Button>
    </Box>
    </Box>
    </>)}

  </ThemeProvider>

)}

export default NursePage;
