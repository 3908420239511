import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActionArea } from '@mui/material';
import { Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import MKBox from 'components/MKBox'
// import bgPattern from "assets/images/shapes/pattern-lines.svg"
//import '@fontsource/berlin-sans-fb'; // Import Berlin Sans FB font

// Define the custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#0C80A4',
    },
    secondary: {
      main: '#C45911',
    },
    background: {
      default: '#cccccc',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
  typography: {
    // fontFamily: 'Berlin Sans FB',
    h3: {
      color: '#777',
    },
    h5: {
      color: '#777',
    },
    body1: {
      color: '#777',
    },
    h6: {
      color: '#FFFFFF',
    },
    body2: {
      color: '#FFFFFF',
    },
  },
});


const LandingPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
          backgroundImage: 'linear-gradient(to right,#003366, #00AACC, #003366)',
          padding: 3,
        }}
    >
        <Typography variant="h4" gutterBottom sx={{ textShadow: "2px 2px 4px #222" ,color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
          Welcome to Data Insights
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: "30px",textShadow: "2px 2px 4px #333" ,color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
          Empowering your business with data-driven solutions
        </Typography>
        <Typography variant="subtitle1"   sx={{ fontSize: "20px" , textShadow: "2px 2px 4px #333" ,color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
          Our team of experts specializes in transforming data into actionable insights across various domains.
        </Typography>
  
       
        <Grid container spacing={4} sx={{ marginTop: 4 }}>
        <Grid item xs={12} sm={6} md={4}>
            <Card
            raised
              sx={{
                backgroundColor: '#f9fcfd',
                 minHeight:"140px"

              }}
            >
              <CardActionArea component={Link} to="policy-search">
                <CardContent>
                  <Typography variant="h6" component="h3"  sx={{textShadow: ".5px .5px 1px #777",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                    Policy Search
                  </Typography>
                  <Typography variant="body2" component="p"  sx={{textShadow: ".5px .5px 1px #fff",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                  Streamline your search through Medicaid policies with our Keyword and Vector Search tool, efficiently matching concepts to relevant policy sections for quick and accurate insights.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        <Grid item xs={12} sm={6} md={4}>
            <Card
             raised
             sx={{
              backgroundColor: '#f9fcfd',
               minHeight:"140px"

            }}
            >
              <CardActionArea component={Link} to="policy-chat">
                <CardContent>
                  <Typography variant="h6" component="h3" sx={{textShadow: ".5px .5px 1px #777",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                    Policy Chat
                  </Typography>
                  <Typography variant="body2" component="p" sx={{textShadow: ".5px .5px 1px #fff",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                  Use natural language to ask questions and extract valuable insights from Medicaid policy documents, complete with document citations. 
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
             raised
             sx={{
              backgroundColor: '#f9fcfd',
               minHeight:"140px"

            }}
            >
              <CardActionArea component={Link} to="contract-analysis">
                <CardContent>
                  <Typography variant="h6" component="h3"  sx={{textShadow: ".5px .5px 1px #777",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                    Contract Analysis
                  </Typography>
                  <Typography variant="body2" component="p"  sx={{textShadow: ".5px .5px 1px #fff",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                  Employ natural language to probe contracts, extracting critical insights for effective contract monitoring, complete with precise document citations.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Card
            raised
            sx={{
              backgroundColor: '#f9fcfd',
               minHeight:"140px"
            }}
            >
              <CardActionArea component={Link} to="patient-check-in">
                <CardContent>
                  <Typography variant="h6" component="h3"  sx={{textShadow: ".5px .5px 1px #777",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                    Patient Check-in
                  </Typography>
                  <Typography variant="body2" component="p"  sx={{textShadow: ".5px .5px 1px #fff",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                  Enhance initial assessments with our patient check-in bot, designed to capture visit reasons and optimize office workflow. Integrate with EHR to improve care coordination.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card
             raised
             sx={{
              backgroundColor: '#f9fcfd',
              minHeight:"140px"

            }}
            >
              <CardActionArea component={Link} to="knowli-dashboards">
                <CardContent>
                  <Typography variant="h6" component="h3"  sx={{textShadow: ".25px .25px 1px #777",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                    Knowli Dashboards
                  </Typography>
                  
                  <Typography variant="body2" component="p"  sx={{textShadow: ".5px .5px 1px #fff", color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                  Explore Knowli's public healthcare projects and see how we transform data into actionable dashboards for Florida’s health initiatives.
                  </Typography>
                 
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4}>
            <Card
             raised
             sx={{
              backgroundColor: '#f9fcfd',
               minHeight:"140px"

            }}
            >
              <CardActionArea component={Link} to="ontology-generator">
                <CardContent>
                  <Typography variant="h6" component="h3"  sx={{textShadow: ".5px .5px 1px #777",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                    Ontology Generator (Coming Soon)
                  </Typography>
                  <Typography variant="body2" component="p"  sx={{textShadow: ".5px .5px 1px #fff",color:"#005a87", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                  Leverage ontology graphs to unlock and visualize key concepts and relationships in your documents, enhancing comprehension and discovery.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>  */}

        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;

