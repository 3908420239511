import React, { useState } from 'react';
import { TextField, Button, MenuItem, Typography, Container, Box, Alert } from '@mui/material';
import MasonryGrid from 'components/MasonryGrid';

const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
  { value: 'other', label: 'Other' },
];

const PatientCheckInForm = ({ onFormSubmit }) => {
  const [formValues, setFormValues] = useState({
    firstName: '',
    middleInitial: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    phoneNumber: '',
    email: '',
    emergencyContactName: '',
    emergencyContactPhone: '',
    emergencyContactRelationship: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((field) => {
      if (!formValues[field] && field !== 'middleInitial') {
        newErrors[field] = true;
      }
    });
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      onFormSubmit(formValues);
      setSubmitted(true);
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Container  maxWidth="md" sx={{backgroundColor:"#004778",  paddingBottom:5, paddingTop:1}}>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Patient Check-In Form
        </Typography>
        {submitted && (
          <Alert severity="success">Form submitted successfully!</Alert>
        )}
        <form onSubmit={handleSubmit} >
          <MasonryGrid columns={3} spacing={2}>
            <TextField
              required
              fullWidth
              id="firstName"
              name="firstName"
              label="First Name"
              value={formValues.firstName}
              onChange={handleChange}
              error={errors.firstName}
              helperText={errors.firstName && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              fullWidth
              id="middleInitial"
              name="middleInitial"
              label="Middle Initial"
              value={formValues.middleInitial}
              onChange={handleChange}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formValues.lastName}
              onChange={handleChange}
              error={errors.lastName}
              helperText={errors.lastName && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
        </MasonryGrid>
        <MasonryGrid columns ={2} spacing={2}>
            <TextField
              required
              fullWidth
              id="dateOfBirth"
              name="dateOfBirth"
              label="Date of Birth"
              type="date"
              InputLabelProps={{ shrink: true }}
              value={formValues.dateOfBirth}
              onChange={handleChange}
              error={errors.dateOfBirth}
              helperText={errors.dateOfBirth && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="gender"
              name="gender"
              label="Gender"
              select
              value={formValues.gender}
              onChange={handleChange}
              error={errors.gender}
              helperText={errors.gender && "This field is required"}
            >
              {genders.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={{color:"#444"}}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              fullWidth
              id="address"
              name="address"
              label="Address"
              value={formValues.address}
              onChange={handleChange}
              error={errors.address}
              helperText={errors.address && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="city"
              name="city"
              label="City"
              value={formValues.city}
              onChange={handleChange}
              error={errors.city}
              helperText={errors.city && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="state"
              name="state"
              label="State"
              value={formValues.state}
              onChange={handleChange}
              error={errors.state}
              helperText={errors.state && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="zipCode"
              name="zipCode"
              label="Zip Code"
              value={formValues.zipCode}
              onChange={handleChange}
              error={errors.zipCode}
              helperText={errors.zipCode && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="phoneNumber"
              name="phoneNumber"
              label="Phone Number"
              value={formValues.phoneNumber}
              onChange={handleChange}
              error={errors.phoneNumber}
              helperText={errors.phoneNumber && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="email"
              name="email"
              label="Email Address"
              value={formValues.email}
              onChange={handleChange}
              error={errors.email}
              helperText={errors.email && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}

            />
            </MasonryGrid>
            <MasonryGrid columns={3}>
            <TextField
              required
              fullWidth
              id="emergencyContactName"
              name="emergencyContactName"
              label="Emergency Contact Name"
              value={formValues.emergencyContactName}
              onChange={handleChange}
              error={errors.emergencyContactName}
              helperText={errors.emergencyContactName && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="emergencyContactPhone"
              name="emergencyContactPhone"
              label="Emergency Contact Phone Number"
              value={formValues.emergencyContactPhone}
              onChange={handleChange}
              error={errors.emergencyContactPhone}
              helperText={errors.emergencyContactPhone && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
            <TextField
              required
              fullWidth
              id="emergencyContactRelationship"
              name="emergencyContactRelationship"
              label="Relationship to Emergency Contact"
              value={formValues.emergencyContactRelationship}
              onChange={handleChange}
              error={errors.emergencyContactRelationship}
              helperText={errors.emergencyContactRelationship && "This field is required"}
              InputProps={{
                sx: {
                  color: "#fff", // Change the color to your desired input text color
                }
              }}
            />
          </MasonryGrid>
          <Box sx={{ mt: 3 }}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default PatientCheckInForm;
