import React from 'react';

const MasonryGrid = ({ children, columns = 3, gap = 16}) => {
  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gridGap: `${gap}px`,
    gridAutoRows: 'minmax(100px, auto)',
    gridAutoFlow: 'dense'
  };

  return (
    <div style={gridStyle}>
      {React.Children.map(children, child => (
        <div style={{ marginBottom: `${gap}px`}}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default MasonryGrid;