/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link , useLocation, useNavigate} from "react-router-dom";


// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userpool from "userpool";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";
import { useAuth } from "pages/authentication/AuthContext";

// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";

function SignInCover(user, setUser) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setconfirmNewPassword] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const {signIn} = useAuth();

  const handleSignIn = (e) => {
    e.preventDefault();


    if (rememberMe) {
      // Save the email to localStorage if rememberMe is checked
      localStorage.setItem('rememberedEmail', email);
    } else {
      // Clear the remembered email if rememberMe is not checked
      localStorage.removeItem('rememberedEmail');
    }
    const authenticationData = {
        Username: email, // Using Username to hold email value as per Cognito's requirement
        Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
        Username: email, // Username here is the email used as identifier
        Pool: userpool, // Ensure userPool is correctly initialized elsewhere in your code
    };
    const cognitoUser = new CognitoUser(userData);

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
            const accessToken = result.getAccessToken().getJwtToken();
            // console.log('Access Token:', accessToken);
            signIn(accessToken)
            sessionStorage.setItem("accessToken", accessToken);
            navigate("/pages/projects")
        },
        onFailure: (err) => {
            if (err.message === "User is not confirmed."){
              resendConfirmationCode(cognitoUser);

            }
            else if (err.message === "Incorrect username or password"){
              toast.error(err.message || "Incorrect username or password!", {
                position: "top-center",
                //onClose: () => navigate('/pages/authentication/sign_in', { replace: true })
              })
            }
            else{
            toast.error(err.message || "An error occurred. Please try signing up!", {
              position: "top-center",
              //onClose: () => navigate('/pages/authentication/sign_up', { replace: true })
            });  }
        }
    });
  };

function forgotPassword(){
  if (!email){
    alert("enter email")
    return
  }
    const username = email
    const userData = {
        Username: username,
        Pool: userpool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
        onSuccess: function () {
            console.log('Password reset code sent.');
        },
        onFailure: function(err) {
            console.error('Error sending reset password code:', err);
        },
        inputVerificationCode: function(data) {
            
            console.log('Code sent to:', data);
            // The UI should ask the user to enter the verification code along with a new password.
        }
    });
    setOpenModal(true)
}

  function resendConfirmationCode(cognitoUser) {
    cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
            console.error("Error resending confirmation code:", err);
        } else {
            console.log("Resend confirmation code succeeded:", result);
            toast.error("Verification email resent. Please check your inbox.", {
              position: "top-center",
              //onClose: () => navigate('/pages/authentication/sign_in', { replace: true })
            });
            
        }
    });
  }

  const handleReset = (e)=>{
    e.preventDefault();
    if (!passwordsMatch) {
      alert("Passwords do not match!");
      return;
  }
    const cognitoUser = new CognitoUser({Username: email, Pool: userpool})
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        toast.success("Password reset successful.", {
          position: "top-center",
          onClose: () => navigate('/pages/authentication/sign_in', { replace: true })
      },)},
      onFailure: (err) => {
        toast.error("Unable to reset passord.", {
          position: "top-center",
          onClose: () => navigate('/pages/authentication/sign_up', { replace: true })
      },)}
  });


    handleClose()
  }

const handleClose = () => {
  setOpenModal(false); // Function to close the modal
};

    // Load the remembered username from localStorage when the component mounts
  useEffect(() => {
      const rememberedEmail = localStorage.getItem('rememberedEmail');
      if (rememberedEmail) {
        setEmail(rememberedEmail);
        setRememberMe(true);
      }
    }, [])

    useEffect(() => {
      // Check if there is an email in the state
      if (location.state?.email) {
          setEmail(location.state.email);
      }
  }, [location.state]);

  useEffect(() => {
    // Check if passwords match whenever password or confirmPassword changes
    setPasswordsMatch(newPassword === confirmNewPassword);
}, [newPassword, confirmNewPassword]);



  return (
    <CoverLayout image={bgImage}>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to sign in
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form" onSubmit={handleSignIn}>
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MKBox>
            {/* <MKBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={() => setRememberMe(!rememberMe)}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MKTypography>
            </MKBox> */}
            <MKBox mt={4} mb={1}>
              <MKButton type="submit" variant="gradient" color="info" fullWidth>
                Sign in
              </MKButton>
            </MKBox>
            
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Forgot Password Reset ?{" "}
                <MKTypography
                  onClick={forgotPassword}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  sx={{cursor: 'pointer' }}
                >
                  Password
                </MKTypography>
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/pages/authentication/sign_up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{maxWidth:300 , margin:"auto", marginTop:"50px"}}
        ><Card >
          <MKBox 
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          // mx={2}
          // mt={-3}
          // py={4}
          // mb={1}
          pt={4} 
          pb={3} 
          px={3}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds if we have you in our system
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form" onSubmit={handleReset}>
            <MKBox mb={4}>
              <MKInput
                type="email"
                label="Email"
                value={email}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="john@email.com"
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="text"
                label="Reset Code"
                variant="standard"
                fullWidth
                onChange={(e)=>setVerificationCode(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder=""
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="Enter New Password"
                variant="standard"
                fullWidth
                onChange={(e)=>setNewPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder=""
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                onChange={(e)=>setconfirmNewPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder=""
              />
            </MKBox>
            {!passwordsMatch && confirmNewPassword && (
                    <MKBox style={{ color: 'red' }}>
                        Passwords do not match!
                    </MKBox>
                )}
            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth type="submit">
                reset
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
        </Card>
        </Modal>
      </Card>
    </CoverLayout>
  );
}

export default SignInCover;
