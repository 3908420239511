import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Box, Alert,  MenuItem } from '@mui/material';
import MasonryGrid from 'components/MasonryGrid';



const ContractCheckInForm = ({ onFormSubmit }) => {

  const [formValues, setFormValues] = useState({
    firstName: '',
    assistant_id: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formValues).forEach((field) => {
      if (!formValues[field] && field !== 'middleInitial') {
        newErrors[field] = true;
      }
    });
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      onFormSubmit(formValues);
      setSubmitted(true);
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <Container sx={{backgroundColor:"#004778", borderRadius:"10px", paddingBottom:5, paddingTop:1}}>
       <Typography variant="h4" gutterBottom align='center' sx={{textShadow: "1px 1px 2px #444" }}>
          Contract Chat Box
        </Typography>
      <Box sx={{ mt: 4 }}>
        {submitted && (
          <Alert severity="success">Form submitted successfully!</Alert>
        )}
        <form onSubmit={handleSubmit}>
          <MasonryGrid columns={1} spacing={2}>
            <TextField
              required
              fullWidth
              id="firstName"
              name="firstName"
              label="Name"
              value={formValues.firstName}
              onChange={handleChange}
              error={errors.firstName}
              helperText={errors.firstName && "This field is required"}
              sx={{textShadow: "1px 1px 2px #444" }}
              InputProps={{
                sx: {
                  color: "#ffffff", // Change the color to your desired input text color
                }
              }}
            />
            {/* <TextField
              required
              fullWidth
              id="lastName"
              name="lastName"
              label="Last Name"
              value={formValues.lastName}
              onChange={handleChange}
              error={errors.lastName}
              helperText={errors.lastName && "This field is required"}
              sx={{textShadow: "1px 1px 2px #444" }}
              InputProps={{
                sx: {
                  color: "#444", // Change the color to your desired input text color
                }
              }}
            /> */}
        </MasonryGrid>

        <MasonryGrid columns ={1} spacing={2}>
            {/* <TextField
              required
              fullWidth
              id="state"
              name="state"
              label="Company"
              value={formValues.state}
              onChange={handleChange}
              error={errors.state}
              helperText={errors.state && "This field is required"}
              sx={{textShadow: "1px 1px 2px #444" }}
              InputProps={{
                sx: {
                  color: "#444", // Change the color to your desired input text color
                }
              }}
            /> */}
             
          <TextField
                required
                fullWidth
                select
                id="assistant_id"
                name="assistant_id"
                label="Contract Selection"
                value={formValues.assistant_id}
                onChange={handleChange}
                error={errors.assistant_id}
                helperText={errors.assistant_id && "This field is required"}
                sx={{textShadow: "1px 1px 2px #444" }}
                InputProps={{
                  sx: {
                    color: "#ffffff", // Change the color to your desired input text color
                  }
                }}

              >
                
                <MenuItem value={"deloitte"} sx={{color:"#444"}}>Florida Contract D</MenuItem>
                <MenuItem value={"accenture"} sx={{color:"#444"}}>Florida Contract A</MenuItem>
              </TextField>
            </MasonryGrid>
          <Box sx={{ mt: 3 }}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Container>
  );
};

export default ContractCheckInForm;

