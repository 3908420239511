import React from 'react';

import { Viewer, Worker, Icon } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
// import { pdfjs } from 'react-pdf';
// import { highlightPlugin,Trigger } from '@react-pdf-viewer/highlight';
// import { HighlightArea, RenderHighlightsProps } from '@react-pdf-viewer/highlight';
import "@react-pdf-viewer/highlight/lib/styles/index.css";
// import * as pdfjsLib from "pdfjs-dist";
// Import styles


import { searchPlugin } from "@react-pdf-viewer/search";

import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'; 
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/bookmark/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';

// Define the expand and collapse icons
const ExpandIcon = () => (
    <Icon size={16}>
        <path d="M.541,5.627,11.666,18.2a.5.5,0,0,0,.749,0L23.541,5.627" />
    </Icon>
);

const CollapseIcon = () => (
    <Icon size={16}>
        <path d="M5.651,23.5,18.227,12.374a.5.5,0,0,0,0-.748L5.651.5" />
    </Icon>
);






const PDFViewer = ({ pdfUrl, highlights=null, bookmark=false, customBookmarks=[]}) => {
      // Initialize the default layout plugin
  console.log("These are my highlights", highlights)
//   const bookmarkPassed = bookmark
//   const [keywords, setKeywords]= useState(highlights)
//   const [documentLoad, onDocumentLoad] = useState(false);
  const searchPluginInstance = searchPlugin({ keyword: highlights});
//   const { highlight } = searchPluginInstance;  
//   const handleDocumentLoad = () => {
//       onDocumentLoad(true);
//   };


  const bookmarkPluginInstance = bookmarkPlugin();
  const { Bookmarks } = bookmarkPluginInstance;
  const renderBookmarkItem = customBookmarks.length > 0
  ? ({ onClickItem, defaultRenderTitle }) => (
      <>
          {customBookmarks.map((bookmark, index) => (
              <div key={index} style={{ padding: '6px 12px', cursor: 'pointer' }}
                  onClick={() => onClickItem(bookmark.page - 1)}> {/* Page index is zero-based */}
                  {defaultRenderTitle(bookmark.title)}
              </div>
          ))}
      </>
    )
  : (renderProps) => renderProps.defaultRenderItem(
      renderProps.onClickItem,
      <>
          {renderProps.defaultRenderToggle(<ExpandIcon />, <CollapseIcon />)}
          {renderProps.defaultRenderTitle(renderProps.onClickTitle)}
      </>
  );
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        sidebarTabs: (defaultTabs) => {
            if (bookmark) {
                return [
                    defaultTabs[0], // Keep the default first tab
                    {
                        content: <Bookmarks renderBookmarkItem={renderBookmarkItem} />,
                        icon: <BookmarkBorderIcon />,
                        title: 'Bookmark',
                    },
                    defaultTabs[2], // Keep the default third tab
                ];
            }
            return defaultTabs; // Return default tabs if bookmark is false
        },
    });

  
//   useEffect(() => {
//     if (documentLoad === true && highlights !== "") {
//         console.log(highlights);
//         // highlightPlugin.highlight(highlightedText);
//         console.l
//         highlight(highlights);
//         console.log("highlighted");
//     }
// }, [documentLoad, highlights]);

  if (!pdfUrl) {
          return <div>Loading PDF...</div>;
      }

    //   const renderHighlights = (props) => (
    //     <div>
    //         {props.areas
    //             .filter((area) => area.pageIndex === props.pageIndex)
    //             .map((area, idx) => (
    //                 <div
    //                     key={idx}
    //                     className="highlight-area"
    //                     style={Object.assign(
    //                         {},
    //                         {
    //                             background: 'yellow',
    //                             // opacity: 0.4,
    //                         },
    //                         // Calculate the position
    //                         // to make the highlight area displayed at the desired position
    //                         // when users zoom or rotate the document
    //                         props.getCssProperties(area, props.rotation)
    //                     )}
    //                 />
    //             ))}
    //     </div>
    // );
    // const highlightPluginInstance = highlightPlugin({
    //     renderHighlights,
    //     trigger: Trigger.None,
    // });


    return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.js">
            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, 0.3)',
                    display: 'flex',
                    height: '100%',
                }}
            >
            
                {/* {bookmark && (
                    <div
                        style={{
                            fontSize:"15px",
                            border: '1px solid rgba(0, 0, 0, 0.3)',
                            borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                            overflow: 'auto',
                            width: '20%',
                        }}
                    >
                        <Bookmarks renderBookmarkItem={renderBookmarkItem} />
                    </div>
                )}  */}
                <div style={{ flex: 1, height: '90vh', width: bookmark ? '70vw' : '85vw', marginTop: '5vh' }}>
                {/* <div style={{ flex: 1, height: '90vh', width: '70vw', marginTop: '5vh' }}> */}
                <style>
                {`.rpv-search__highlight { background-color: rgba(235, 73, 128, 0.5) !important; }`}
            </style>
                    <Viewer
                        fileUrl={pdfUrl}
                        
                        plugins={[
                            defaultLayoutPluginInstance,
                            bookmarkPluginInstance,
                            searchPluginInstance
                        ]}
                    />
                </div>
            </div>
        </Worker>
    );
};

export default PDFViewer;