/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import { List, ListItem, ListItemText } from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userpool from "userpool";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";


function SignUpCover() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  let navigate = useNavigate();
  
  // const [email, setEmail] = useState('');

  const onSubmit = event => {
        event.preventDefault();
        if (!passwordsMatch) {
          alert("Passwords do not match!");
          return;
      }
      
        const attributeList = [];
        // const emailAttribute = {
        //     Name: 'email',
        //     Value: email
        // };

        // attributeList.push(new CognitoUserAttribute(emailAttribute));

        userpool.signUp(email, password, attributeList, null ,(err, result) => {
            if (err) {
              if (err.message ==="User already exists"){
                toast.error("Try Signing In or use a new Email", {
                  position: "top-center",
                  onClose: () => navigate('/pages/authentication/sign_in', { replace: true, state: { email: email } })})
                
              }
              else {
              toast.error(err.message || "An error occurred during registration. Try Signing In or use a new Email", {
                position: "top-center",
                onClose: () => navigate('/pages/authentication/sign_up', { replace: true, state: { email: email } })
              }); } 
            }
            else {
            console.log('User registration successful: ' + result.user.getUsername());
            toast.success('Registration successful! Please check your email to verify your account.', {
            position: "top-center",
            onClose: () => navigate('/pages/authentication/sign_in', { replace: true, state: { email: email } })
            
      });}
        });
    };


  
    useEffect(() => {
      // Check if passwords match whenever password or confirmPassword changes
      setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);



  return (
    <CoverLayout image={bgImage} >
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form">

            <MKBox mb={2}>
              <MKInput type="email" label="Email" fullWidth onChange={(e)=>{setEmail(e.target.value)}}/>
            </MKBox>
            <MKBox mb={.5}>
              <MKInput type="password" label="Password" fullWidth onChange={(e)=>{setPassword(e.target.value)}}/>
            </MKBox>
            <MKBox mb={.5}>
            <List>
        <ListItem >
          <ListItemText primary="Minimum password length: 8." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }}/>
        </ListItem>
        <ListItem>
          <ListItemText primary="Must include at least one uppercase letter." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }}/>
        </ListItem>
        <ListItem>
          <ListItemText primary="Must include at least one lowercase letter." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Must include at least one number." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }}/>
        </ListItem>
        <ListItem>
          <ListItemText primary={
            <>
              Must include at least one special character: 
            </>
          } primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }} />
        </ListItem>
        </List>
            
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Confirm Password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </MKBox>
            {!passwordsMatch && confirmPassword && (
                    <MKBox style={{ color: 'red' }}>
                        Passwords do not match!
                    </MKBox>
                )}
            {/* <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MKTypography>
              <MKTypography
              component={Link}
                to="/pages/Authentication/sign_in"
                href="#"
                variant="button"

                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MKTypography>
            </MKBox> */}
            <MKBox mt={3} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                sign up
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/pages/Authentication/sign_in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default SignUpCover;
