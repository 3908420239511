
import { Link } from "react-router-dom";

// typed-js


// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { styled } from '@mui/material/styles';

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

import MKTypography from "components/MKTypography";

// my_components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Images
import bgImage from "assets/images/node_graph_ontology.webp";

// Routes
import routes from "routes";
const StyledLink = styled(Link)({
  color: '#ffffff',
  textDecoration: 'none',
  fontWeight: "bold"
});

function ComingSoon({topic}) {

    const subject = topic;
  // Setting up typedJS


  return (
    <MKBox component="header" position="relative">
      <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
      <DefaultNavbar
        routes={routes}
        sticky
      />
      <MKBox
        minHeight="100vh"
        width="100%"
        sx={{
          backgroundImage: 'linear-gradient(to right,#003366, #00AACC, #003366)',
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          position: "relative",
        }}
      >      
      <MKBox
      component="img"
      src={bgImage}
      alt="Background Image"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        opacity: 0.5, // Adjust opacity as needed
      }}
    />
      <Container>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={{fontFamily:'Avenir, sans-serif',fontWeight:700 }}
            >
              Coming Soon
            </MKTypography>
            <MKTypography
              variant="subtitle1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
              Greatness takes time. Working hard to bring you the best {subject} experience possible. Stay tuned!
              Here is a Link to our current <StyledLink to="/pages/projects" sx={{color:"#ffffff"}}>projects</StyledLink>.
            </MKTypography>     
          </Grid>
        </Container>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

export default ComingSoon;