import React, { useState, useEffect, useRef } from 'react';

import { Box,TextField, Button, Typography, Container} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Modal from '@mui/material/Modal';
import MKBox from 'components/MKBox'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PolicyCheckInForm from './MedicaidCheckinForm';
import MessageCitationList from 'components/MessageList/messageCitationList'
import TypingSpinner from 'components/TypingSpinner';



import PDFViewer from 'components/PDFViewer';
// import { useNavigate } from 'react-router-dom';


const theme = createTheme({
  palette: {
    primary: {
      main: '#0C80A4',
    },
    secondary: {
      main: '#C45911',
    },
    background: {
      default: '#eeeeee',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
  multilineColor:{
    color:'red'
},
  typography: {
    // fontFamily: 'Berlin Sans FB',
    h3: {
      color: '#FFFFFF',
    },
    h5: {
      color: '#FFFFFF',
    },
    body1: {
      color: '#FFFFFF',
    },
    h6: {
      color: '#FFFFFF',
    },
    body2: {
      color: '#FFFFFF',
    },
  },
});



const serverBaseURL = process.env.REACT_APP_BACKEND_URL;

//const serverBaseURL = "http://localhost:8000"

function PolicyPage() {

  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [getPatientInfo,setGetPatientInfo] = useState(true);
  const [thread_id, setThread_id] = useState('');
  const [assistant_id, setAssistantId] = useState('');
  const [loading, setLoading]= useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handle_loading_message, setHandleLoadingMessage] = useState(false)
  
  const [pdfUrl, setPdfUrl] = useState('');

 
  const toTitleCase=(str)=> {
    return str.split('_').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};
  


  const handleFormSubmit = (formData) => {
    setGetPatientInfo(false)
    setLoading(true)
    setAssistantId(formData.assistant_id)
    handleInitialize(formData.firstName, formData.assistant_id)
    // You can now use formData as needed, e.g., send it to an API, store in state, etc.
  };
  
  const handleInitialize = async (firstName, assistant_id)=>{
    console.log("This is assistant_id ", assistant_id)
    
    try {
      const response = await fetch(`${serverBaseURL}/policy-api/get-policy-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName:`${firstName}`, assistant_id:`${assistant_id}` }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(false)
      
      const data = await response.json();
      setThread_id(data.thread_id)
      setHandleLoadingMessage(false)
      typeOut(data.message, assistant_id); 

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  const handleSend = () => {
    setHandleLoadingMessage(true)
    if (input.trim() !== '') {
      const newMessage = { user: 'You', text: input, time: new Date().toLocaleTimeString() };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const query = input
      setInput('');
      handleResponse(query)
    }
  };

  const handleResponse = async (query) => {
    if (query ===""){

      return
    }
    try {
    
      const response = await fetch(`${serverBaseURL}/policy-api/continue-policy-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id : thread_id, new_message: query, assistant_id: assistant_id }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok now');
      }
     
      const data = await response.json();
     
   
      typeOut(data.message, assistant_id); 
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  const handleOpen = async (citation) => {
    
    const filename = citation.file_name;
    try {
        // Initiate both fetch operations concurrently
        const pdfFetchPromise = fetch(`${serverBaseURL}/policy-api/get_citations`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({filename: filename, assistant_id: assistant_id})
        });


        // Wait for both promises to resolve
        const pdfResponse = await pdfFetchPromise;

        if (!pdfResponse.ok) {
            throw new Error('Failed to fetch PDF.');
        }


        const blob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);

        

         // Assume highlightsData is already in the correct format

        if (pdfUrl) {
          console.log("I am in modal")
          openModal()
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  const openModal = () =>{
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false);
   
    
  };

  const typeOut = (text_returned,assistant_id) => {
    setHandleLoadingMessage(false)
    const assistant_title = toTitleCase(assistant_id)
    const text = text_returned.message
    const citations = text_returned.citations
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];

      if (lastMessage && lastMessage.user === `${assistant_title} Medicaid AI Assistant`) {
        const updatedMessage = {
          ...lastMessage,
          text: lastMessage.text + text + (/[.!?]$/.test(text) ? ' ' : '')  // Add a space only if the text ends with a punctuation mark
        };
        return [...prevMessages.slice(0, -1), updatedMessage];
      } else { 
      
        let user_message = `${assistant_title} Medicaid AI Assistant`
        const newMessage = {
          user: user_message,
          text: text + (/[.!?]$/.test(text) ? ' ' : ''),  // Add a space only if the text ends with a punctuation mark
          time: new Date().toLocaleTimeString(),
          citations: citations
        };
        
        return [...prevMessages, newMessage];
      }
    });

  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Cleanup on component unmount
  useEffect(() => { 
    scrollToBottom();
  }, [messages]);

  useEffect(() => { 
    scrollToBottom()
  },[handle_loading_message]);


  return (
    
    <ThemeProvider theme={theme}>
    {getPatientInfo ? (
     <Box sx={{display: 'flex', flexDirection: 'column', height: '100vh', background: "linear-gradient(to right,#003366, #00AACC, #003366)", color: '#fff'}}>
     <MKBox>
        <Typography variant="h4" gutterBottom sx={{ textShadow: "2px 2px 4px #000000" , flexGrow: 1, marginTop:"50px" , color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}}textAlign={"center"}>
        Explore Medicaid Policies with Ease
        </Typography>
        </MKBox>
        <MKBox>

        <Typography variant="h6"  gutterBottom  sx={{ textShadow: "2px 2px 4px #000000" ,marginRight: "100px",marginLeft:"100px", flexGrow: 1,color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}} textAlign={"center"}>
        Effortlessly find answers to your Medicaid policy questions. Our app allows you to 
        interact with Medicaid policies from Florida, South Carolina, and Michigan, 
        providing clear and accurate answers to your queries. Additionally, you'll receive 
        direct references to specific policy locations, ensuring you have all the information you 
        need at your fingertips. 
        </Typography>
        </MKBox>
  


   <div style={{width:"60%",margin:'auto', marginTop:"50px"}}>
      <PolicyCheckInForm onFormSubmit={handleFormSubmit} />
    </div>
    </Box>):(<>
      {loading ? (
       
        <MKBox sx={{ display: 'flex',  justifyContent: 'center', paddingTop:15,paddingBottom:14, backgroundColor: theme.palette.background.default}}>
       <TypingSpinner message={`Loading ${toTitleCase(assistant_id)} Medicaid Policy. Please Wait`}/>
       </MKBox>
      ):(
      <>
     <Box sx={{display: 'flex', flexDirection: 'column', height: '90vh', background: "linear-gradient(to right,#003366, #00AACC, #003366)", color: '#fff'}}> 
     <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ 'display':'flex',
          'justify-content': 'center',
          'align-items': 'center',
          'position':' fixed',
          'top': 0,
          'left': 0,
         'width': '100%',
          'height': '100%',
          'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
          'z-index': 1000 /* Ensure it's on top */}}
      >
        <MKBox  >
          <Container >
       <PDFViewer pdfUrl={pdfUrl} />
        </Container>
        </MKBox>
      </Modal>
    <MessageCitationList messages={messages} handleOpen={handleOpen}  loading={handle_loading_message}/>
    <Box sx={{ display: 'flex', padding: 1,paddingBottom:5, backgroundColor:"#004778" }}>
      <TextField
        variant="outlined"
        placeholder="Type a message"
        fullWidth
        multiline
        inputProps={{ style: { color: "#fff" } }}
        minRows={2}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => { if (e.key === 'Enter' && !e.shiftKey) { handleSend(); e.preventDefault(); } }}
        sx={{
          
          '& .MuiInputBase-root': {
            color: '#fff',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#fff',
            },
          },
          paddingLeft:20,
          paddingRight:10,
       
        }}
      />
      <Button variant="contained" color="primary" onClick={handleSend}   sx={{
    marginLeft: 1,
    backgroundColor: '#0C80A4',
    '&:hover': {
      backgroundColor: '#065a7c',
    },

  }}>
        <SendIcon />
      </Button>
      </Box>
    </Box>
    </>)}</>)}

  </ThemeProvider>

)}

export default PolicyPage;
